import axios from 'axios';

// 通用的 headers 配置
const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
};

export default class ApiBuilder {

    // 通用构建请求方法
    static build(url, paras = {}) {
        if (paras.data && paras.responseType !== "blob") {
            let paramsArray = [];
            // 拼接 URL 参数
            Object.keys(paras.data).forEach(key => paramsArray.push(key + '=' + paras.data[key]));
            if (url.search(/\?/) === -1) {
                url += '?' + paramsArray.join('&');
            } else {
                url += '&' + paramsArray.join('&');
            }
        }

        // 配置请求
        const config = {
            method: paras.method || 'GET',   // 默认为 GET 请求
            headers: paras.headers || headers,  // 设置默认的 headers
            params: paras.data || {},  // 如果有 data 则放在 params 中
            body: paras.body || {},
            withCredentials: true,  // 保持跨域请求时携带 Cookies
            responseType: paras.responseType || 'json',  // 设置响应类型，默认为 json
            onDownloadProgress: paras.onDownloadProgress
        };

        // 发送请求
        return axios(url, config)
            .then(response => {
                const res = response.data;
                // 如果返回的数据是文件流（Blob）
                if (config.responseType === 'blob') {
                    const fileURL = window.URL.createObjectURL(res);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    a.download = paras.fileName || 'downloaded_file';
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                    return;
                }
                return res;
            })
            .catch(error => {
                console.error('ApiBuilder -> build error: ', error);

                // 你可以根据不同错误类型做不同的处理
                const errorDetails = new Error();
                errorDetails.response = error.response;
                errorDetails.message = error.message;
                console.error('ApiBuilder -> build error details: ', errorDetails);

                // 返回一个错误信息或者抛出异常
                throw errorDetails;
            });
    }
}