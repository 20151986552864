import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import routes from './routes/routes';

export default () => (
    <Router>
        <App>
            <Routes>
                {routes.map(({ path, element }, index) => (
                    <Route key={index} path={path} element={element} />
                ))}
            </Routes>
        </App>
    </Router>
);
