import React from 'react';
import "./DownloaderIntro.less"
import Free from '../../../../assets/IntroIcon/Free.png';
import icon from '../../../../assets/IntroIcon/icon.png';
import icon3 from '../../../../assets/IntroIcon/Icon 3.png';
import icon4 from '../../../../assets/IntroIcon/icon 4.png';
import icon5 from '../../../../assets/IntroIcon/icon 5.png';
import icon6 from '../../../../assets/IntroIcon/icon 6.png';

const introData = [
    {
        title: '100% Free to use',
        icon: Free,
        text: "We proudly provide a 100% free service that allows you to download as many videos as you like, with absolutely no restrictions or hidden fees."
    }, {
        title: 'No Sign Up or Login ',
        icon: icon,
        text: "Start downloading instantly without the need for registration or log-ins. We prioritize your convenience and safeguard your privacy every step of the way."
    }, {
        title: 'High Quality Download',
        icon: icon3,
        text: "ViDown supports to download videos in their original quality, including 1080p and 720p. All music will be downloaded in MP3 320kbps."
    }, {
        title: '10,000+ Sites Support',
        icon: icon4,
        text: "ViDown supports over 10,000 websites, allowing you to download videos from a vast array of platforms, including Facebook, TikTok, X(Twitter), and many more."
    }, {
        title: 'Web-Based Downloader',
        icon: icon5,
        text: "ViDown ensures seamless YouTube video downloads across all platforms, like Mac, Windows, or Android device. Simply open your web browser, and you're ready to go!"
    }, {
        title: 'Flash Download',
        icon: icon6,
        text: "Your time is precious, and we get that. That’s why ViDown delivers fast and efficient download speeds, so you can grab your favorite YouTube videos in an instant."
    },]

const DownloaderIntro = () => {
    return (
        <div className='download-Intro'>
            <div className='download-title'>
                <h2>Why Choose ViDown Online Video Downloader</h2>
            </div>
            <div className='download-content'>
                {
                    introData.map(item => {
                        return (
                            <div className='download-Intro-item'>
                                <div className='item-tag'>
                                    <img src={item.icon} alt='#' />
                                </div>
                                <div className='item-title'><span>{item.title}</span></div>
                                <div className='item-text'><span>{item.text}</span></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default DownloaderIntro;