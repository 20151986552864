import React from 'react';
import 'antd/dist/antd.less';

import {
  Layout,
  ConfigProvider
}

  from 'antd';
import Home from './pages/components/Home/HomePage.js';
import HeaderBar from './pages/components/HeaderBar/HeaderBar.js'
import Footer from './pages/components/Footer/Footer.js'

import zhCN from 'antd/es/locale/zh_CN';

import './App.less';

const { Content } = Layout;

const App = ({ children }) => {
  return (<ConfigProvider locale={zhCN}>
    <Layout className="layout">
      <HeaderBar />
      <Content>
        {/* <Home downloadType={downloadType} /> */}
        {children}
      </Content>
      <Footer />
    </Layout>
  </ConfigProvider>);
}

export default App;
