import React from 'react';
import { Menu, Dropdown, Layout } from 'antd';
import './HeaderBar.less';
import { Link, useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const HeaderBar = () => {
    const navigate = useNavigate();

    const languageMenu = (
        <Menu>
            <Menu.Item key="1">English</Menu.Item>
            <Menu.Item key="2">Español</Menu.Item>
            <Menu.Item key="3">中文</Menu.Item>
        </Menu>
    );

    const dowmloadMenu = (
        <Menu>
            <Menu.Item key="/Bilibili" onClick={() => handleClick('/Bilibili')}>bilibili Downloader</Menu.Item>
            <Menu.Item key="/Tiktok" onClick={() => handleClick('/Tiktok')}>tiktok Downloader</Menu.Item>
            <Menu.Item key="/Instagram" onClick={() => handleClick('/Instagram')}>instagram Downloader</Menu.Item>
        </Menu>
    )

    const handleClick = (path) => {
        navigate(path); // 跳转到指定路由
    }

    return (
        <Header className="header-bar">
            <div className="header-bar-container">
                <div className="logo">ViDown.cc</div>
                <div className="menu">
                    <div className="header-bar-item">
                        <Link to='/Youtobe'>youtobe Downloader</Link>
                    </div>
                    <div className="header-bar-item">
                        <Link to='/Facebook'>Facebook Downloader</Link>
                    </div>
                    <div className="header-bar-item">
                        <Link to='/Twitter'>Twitter Downloader</Link>
                    </div>
                    <Dropdown overlay={dowmloadMenu}>
                        <div className="header-bar-item">
                            <span>{`More Sites >`}</span>
                        </div>
                    </Dropdown>
                    {/* <Dropdown overlay={languageMenu}>
                        <div className="header-bar-item">
                            <a onClick={(e) => e.preventDefault()}>English</a>
                        </div>
                    </Dropdown> */}
                </div>
            </div>
        </Header>

    );
};

export default HeaderBar;
