import React from 'react';
import { Layout } from 'antd';
import { Link } from "react-router-dom";
import './Footer.less';

const { Footer } = Layout;

const FooterContainer = () => {
    return (
        <Footer className="footer-container">
            <div className="footer">
                <Link to='/Copyright' target='_blank'>Terms of Service</Link>
                <Link to='/PrivacyPolicy' target='_blank'>Privacy Policy</Link>
                <Link to='/TermsOfUse' target='_blank'>Copyright</Link>
            </div>
            <div className='footer-info'>
                <span>©2024 YouTube Video Downloader. All Rights Reserved.</span>
            </div>

        </Footer>
    );
};

export default FooterContainer;
