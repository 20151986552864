import React, { useState } from 'react';
import { Input, Button } from 'antd';
import './UrlInputForm.less'

const UrlInputForm = ({ onDownload }) => {
    const [url, setUrl] = useState('');

    const handleChange = (e) => {
        setUrl(e.target.value);
    };

    const handleClick = () => {
        onDownload(url);
    };

    return (
        <div className="input-form">
            <Input
                size="large"
                value={url}
                onChange={handleChange}
                placeholder="Paste link here to start download"
                className="input-url"
                allowClear
                bordered={false}
            />
            <Button
                type="primary"
                className="download-btn"
                onClick={handleClick}
            >Download</Button>
        </div>
    );
};

export default UrlInputForm;
