import {
    BASE_URL
} from '../api-config'
import ApiBuilder from '../ApiBuilder';

export default class DownLoadApi {

    //获取媒体信息
    static getMediaInfo(url) {
        return ApiBuilder.build(`${BASE_URL}/api/v1/media/info?url=${url}`, {
            method: 'GET',
        })
    }

    //媒体下载
    static downloadMedia(params, onProgress) {
        const { url, ext, format_id, title } = params;
        const paras = {
            method: 'GET',
            data: {
                url: url,
                ext: ext,
                format_id: format_id,
                title: title
            },
            responseType: 'blob',  // 请求文件流
            fileName: `${title}.${ext}`,  // 自定义文件名
            onDownloadProgress: (event) => {
                if (event.lengthComputable) {
                    // 计算进度百分比
                    const progress = (event.loaded / event.total) * 100;
                    onProgress(progress);  // 调用外部传入的回调函数更新进度
                }
            },
        };

        return ApiBuilder.build(`${BASE_URL}/api/v1/media/download`, paras)
    }
}
