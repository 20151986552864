import React from 'react';
import "./DownloadTextbook.less"
import downloadVideoImg from '../../../../assets/download-video.png';
import copyLinkImg from '../../../../assets/copy-link.png';
import pasteLinkImg from '../../../../assets/paste-link.png';

const data = [{
    title: 'Copy Video URL',
    text: 'Open YouTube and find the video you want to download. Copy the video URL',
    index: 1,
    indexColor: '#ECF7FF',
    img: copyLinkImg
}, {
    title: 'Paste Video URL to ViDown',
    text: 'Paste the video link into Search box and click the Download button to fetch info.',
    index: 2,
    indexColor: '#FFF1EF',
    img: pasteLinkImg
}, {
    title: 'Download YouTube Video',
    text: 'You can select to download YouTube to MP4 or MP3 with the best quality.',
    index: 3,
    indexColor: '#F8EEFF',
    img: downloadVideoImg
},]

const DownloadTextbook = () => {
    return (
        <div className='download-textbook'>
            <div className='textbook-title'>
                <h2>How to Use the Video Downloader to Download Any Video</h2>
            </div>
            <div className='textbook-content'>
                {data.map(item => {
                    return (
                        <div className='textbook-item'>
                            <div className='textbook-item-step' style={{ backgroundColor: item.indexColor }}>
                                <span>{item.index}</span>
                            </div>
                            <div className="textbook-item-info">
                                <div className='textbook-item-title'>
                                    <span>{item.title}</span>
                                </div>
                                <div className='textbook-item-text'>
                                    <span>{item.text}</span>
                                </div>
                            </div>
                            <div className='textbook-item-img'>
                                <img src={item.img} alt="广告广告广告广告广告广告广告" />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DownloadTextbook;