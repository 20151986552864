import React from 'react';
import { Navigate } from 'react-router-dom';
import YouTubePage from '../pages/YouTubePage/YouTubePage';
import TiktokPage from '../pages/TiktokPage/TiktokPage';
import FacebookPage from '../pages/FacebookPage/FacebookPage';
import TwitterPage from '../pages/TwitterPage/TwitterPage';
import BilibiliPage from '../pages/BilibiliPage/BilibiliPage';
import InstagramPage from '../pages/InstagramPage/InstagramPage';
import Copyright from '../pages/components/TermsService/Copyright/Copyright'
import PrivacyPolicy from '../pages/components/TermsService/PrivacyPolicy/PrivacyPolicy'
import TermsOfUse from '../pages/components/TermsService/TermsOfUse/TermsOfUse'

const routes = [
    {
        path: '/YouTube',
        element: <YouTubePage />,
    },
    {
        path: '/Tiktok',
        element: <TiktokPage />,
    },
    {
        path: '/Facebook',
        element: <FacebookPage />,
    },
    {
        path: '/Twitter',
        element: <TwitterPage />,
    },
    {
        path: '/Bilibili',
        element: <BilibiliPage />,
    },
    {
        path: '/Instagram',
        element: <InstagramPage />,
    },
    {
        path: '/Copyright',
        element: <Copyright />,
    },
    {
        path: '/PrivacyPolicy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/TermsOfUse',
        element: <TermsOfUse />,
    },
    {
        path: '*',
        element: <Navigate to="/YouTube" replace />,
    },
];


export default routes;
