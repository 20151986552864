import React from "react";
import util from '../../../../utils/util'
import "./VideoInfo.less";

const VideoInfo = ({ videoInfo }) => {

    return (
        <div className="video-info-container">
            <div className="video-cover">
                <img src={videoInfo.img} alt="Paris"></img>
            </div>
            <div className="video-synopsis">
                <span>{videoInfo.title}</span>
                <div className="video-duration">
                    <span>duration:{util.secondsToHMS(videoInfo.duration)}</span>
                </div>
            </div>

        </div>
    )
}

export default VideoInfo;