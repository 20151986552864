import React from 'react';
import './Copyright.less'
// import { useTranslation } from 'react-i18next';

const Copyright = () => {
    return (
        <div className="copyright">
            <div className="copyright-container">
                <h1>Copyright</h1>
                <p>ViDown respects the intellectual property rights of others and requests you to do the same. ViDown does not permit infringement of intellectual property rights on its platform and will promptly suspend commercial content (served via a publicly available web address / URL) from being able to be converted and downloaded by its platform when kindly notified.</p>
                <p>If you’re a content creator/owner, copyright owner, or an agent thereof and would like to disable the possible use of ViDown’s platform to convert your publicly available content(s), please kindly send me a request via email at support@vidown.cc with the following information:</p>
                <p>The URL(s) and description(s) of the content(s) you want us to block.</p>
                <p>A form of electronic or physical evidence showing that you have the rights to act for the content(s).</p>
                <p>Contact information that is reasonably sufficient to permit me to contact you, such as telephone number, and a valid email address.</p>
                <h2>General Notice:</h2>
                <p>ViDown respects the intellectual property rights of all content creators, whether their work is hosted on YouTube or any other service. We strongly encourage our users to respect these rights as well.</p>
                <h2>Content Ownership and Copyright Compliance:</h2>
                <p>All videos, images, music, and any other content downloaded via ViDown: Online Video Downloader remain the property of their respective copyright owners. Users of this service agree to bear sole responsibility for any copyright infringement issues that may arise from the improper use or distribution of copyrighted content.</p>
                <h2>Intended Use:</h2>
                <p>The ViDown: Online Video Downloader service is intended solely for personal, non-commercial use. Users are prohibited from distributing, reproducing, displaying publicly, or performing any content downloaded through the service without obtaining the necessary permissions from the respective copyright holders</p>
                <h2>Liability:</h2>
                <p>ViDown: Online Video Downloader does not host any copyrighted content on its servers. We do not support or condone the illegal distribution of copyrighted material. Users are solely responsible for ensuring the legality of downloading and using any content through the service.</p>
                <h2>Copyright Infriction and Content Removal:</h2>
                <p>Copyright holders who believe their content has been uploaded to YouTube and downloaded through ViDown: Online Video Downloader in violation of their rights are encouraged to contact us. We are committed to working with copyright holders to address any concerns, which may include removing content from our service upon request.</p>
                <h2>Contact Information:</h2>
                <p>For copyright inquiries or content removal requests, please contact us at support@vidown.cc.</p>
                <h2>Final Reminder:</h2>
                <p>It is important to understand the legal implications of downloading and using copyrighted content. We urge our users to exercise caution and ensure they have all necessary rights or permissions when using any downloaded material.</p>
            </div>

        </div>
    );
};

export default Copyright;