import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import "./DownloadTable.less";
import util from "../../../../utils/util.js";

const fileTypeMap = {
    1: "Video",
    0: "Audio"
}

const DownloadTable = ({ downloadInfo, onDownload }) => {
    const [showAllList, setShowAllList] = useState(false);
    const [downloadList, setDownloadList] = useState(downloadInfo || []);

    useEffect(() => {
        if (downloadInfo && downloadInfo.length > 6) {
            setDownloadList(downloadInfo.slice(0, 6));
        } else {
            setDownloadList(downloadInfo);
        }
    }, [downloadInfo])

    // 表格列定义
    const columns = [
        {
            title: "",
            dataIndex: "type",
            key: "type",
            render: (text, record, index) => {
                // 只在首个同类型数据行展示类型
                if (index > 0 && downloadList[index - 1].type === text) {
                    return null;
                }
                return (
                    <span className="table-type">{fileTypeMap[text]}</span>
                );
            },
        },
        {
            title: "",
            dataIndex: "quality",
            key: "quality",
            render: (text, record) => (
                <div>
                    {
                        record.type === 1
                            ?
                            <span>{record.ext}-{text}</span>
                            :
                            <span>{`${record.ext}-${record.bitrate}bit`}</span>

                    }

                </div>
            ),
        },
        {
            title: "",
            dataIndex: "filesize",
            width: 150,
            // align: 'flex',
            key: "filesize",
            render: text => {
                return (<div>{util.convertBitToUnit(text || 0)}</div>);
            }
        },
        {
            title: "",
            dataIndex: "action",
            format_id: "action",
            render: (text, record) => (
                <Button type="primary" className="download-button" onClick={() => handleDownload(record)}>
                    DOWNLOAD
                </Button>
            ),
        },
    ];
    const handleShowAllList = () => {
        const newShowAllList = !showAllList;
        setShowAllList(newShowAllList);
        setDownloadList(newShowAllList ? downloadInfo : downloadInfo.slice(0, 6));
    };

    const handleDownload = (record) => {
        const params = {
            ext: record.ext,
            format_id: record.format_id,
        }
        onDownload(params);
    }

    const footButton = () => {
        return (
            <div className="show-more-container">
                <Button type="primary" filesize="large" className="show-more-button" onClick={handleShowAllList}>
                    {showAllList ? "SHOW LESS" : "SHOW MORE"}
                </Button>
            </div>
        )
    }

    return (
        <div className="download-table-container">
            <Table
                dataSource={downloadList}
                columns={columns}
                pagination={false}
                rowClassName={(record) =>
                    record.quality.includes("No Audio") ? "no-audio-row" : ""
                }
                bordered={true}
                showHeader={false}
                footer={downloadInfo.length > 6 ? footButton : false}
            />
        </div>
    );
};

export default DownloadTable;
