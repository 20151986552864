import React, { useState, useEffect } from 'react';
import { Modal, Progress, Button } from 'antd';
import DownloadApi from '../../../../axios/apis/DownloadApi.js'
import "./DownloadModal.less"
import adImg from '../../../../assets/111.png'

const DownloadModal = ({ visible, onClose, downloadParams, videoInfo }) => {
    const [downloadProgress, setDownloadProgress] = useState(0); // 下载进度
    const [isDownloading, setIsDownloading] = useState(false); // 是否正在下载

    const startDownload = async () => {
        setIsDownloading(true);
        const { url, ext, format_id, title } = downloadParams;
        try {
            const params = {
                ...downloadParams
            };  // 这里是你的请求参数
            const downloadMedia = await DownloadApi.downloadMedia(params, onProgress);
            const data = downloadMedia;

            if (data instanceof Blob) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    // 完成下载后创建下载链接
                    const fileURL = window.URL.createObjectURL(data);
                    const a = document.createElement('a');
                    a.href = fileURL;
                    const fileName = title ? `${title}.${ext}` : 'downloaded_file.mp4';
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(fileURL);

                    setDownloadProgress(100);
                    setIsDownloading(false);
                };
                reader.readAsDataURL(data);

            } else {
                console.error('Expected Blob, but received:', data);
                setIsDownloading(false);
            }
        } catch (err) {
            console.error('Download failed', err);
            setIsDownloading(false);
        }
    };

    const onProgress = (progress) => {
        setDownloadProgress(progress)
    };

    return (
        <Modal
            open={visible}
            title={<div className='title'><span>{downloadParams.title}</span></div>}
            footer={isDownloading ? <Progress percent={Number(downloadProgress.toFixed(0))} /> : null}
            onCancel={onClose}
            maskClosable={true}
            closable={false}
            centered={true}
            className={'downloadModal'}
            afterClose={() => { setDownloadProgress(0); setIsDownloading(false) }}
        >
            <div className="modal">
                {/* 显示广告（示例内容） */}
                <div className="advertisement">
                    <img src={videoInfo.img || adImg} alt="广告广告广告广告广告广告广告" />
                </div>
                {downloadProgress !== 100 ?
                    < Button type="primary" onClick={startDownload} disabled={isDownloading} className='downloadButton' size='large'>
                        {isDownloading ? '下载中...' : '现在下载'}
                    </Button>
                    : < Button type="primary" onClick={onClose} className='downloadButton' size='large'>关闭</Button>
                }
            </div>

        </Modal>
    );
};

export default DownloadModal;
