
import React, { useState } from 'react';
import { Collapse } from 'antd';
import './Disclaimer.less'

const { Panel } = Collapse;

const Disclaimer = () => {
    return (
        <div className="disclaimer">
            <div className='disclaimer-title'>
                <h3>Disclaimer</h3>
            </div>
            <div className='disclaimer-text'>
                <span>This website is intended for personal use only. Downloading copyrighted material is strictly prohibited. By using this website, you agree to abide by our Terms of Service and accept full responsibility for any copyright violations or misuse.</span>
            </div>

        </div>
    );
};

export default Disclaimer;
