import React from 'react';
import './PrivacyPolicy.less'
// import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="privacy-policy-container">
                <h2>Introduction:</h2>
                <p>Welcome to ViDown (vidown.cc). We understand that privacy online is important to users of our Site, especially when conducting business. This statement governs our privacy policies with respect to those users of the Site ("Visitors") who visit without transacting business and Visitors who register to transact business on the Site and make use of the various services offered by ViDown.</p>
                <h2>Personal Information Collected:</h2>
                <h2>Automatic Collection:</h2>
                <p>Our servers automatically recognize Visitors' domain names and IP addresses (the number assigned to computers on the Internet). No personal information about you is revealed in this process. The Site may also gather anonymous "traffic data" that does not personally identify you but may be helpful for marketing purposes or for improving the services we offer</p>
                <h2>Cookies:</h2>
                <p>ViDown may use cookies to remember Visitor preferences and maximize the performance of our services. Additionally, cookies help us track user behavior on our Site, allowing for enhanced service customization.</p>
                <h2>Voluntarily Provided Information:</h2>
                <p>When you use ViDown, you may choose to provide personal information to us, such as your email address. We will use this information to process your requests and may use it to provide you with additional information at a later time. We will not disclose such information to third parties, except as specified in this policy.</p>
                <h2>Use of Information Collected:</h2>
                <p>ViDown uses any information voluntarily given by our users to enhance their experience on our site, whether to provide interactive or personalized elements on the sites or to better prepare future content based on the interests of our users. We do not share personal information with third parties without your explicit permission.</p>
                <h2>Data Security:</h2>
                <p>We take reasonable steps to maintain the safety of your personal information. However, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot warrant the security of your personal data when it is being transmitted</p>
                <h2>Your Rights:</h2>
                <p>You may have rights under data protection laws in relation to your personal data. These may include the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data, and (where the lawful ground of processing is consent) to withdraw consent.</p>
                <h2>Changes to This Privacy Policy:</h2>
                <p>ViDown reserves the right to change this privacy policy as we deem necessary or appropriate due to legal compliance requirements or changes in our business practices. If you have provided us with an email address, we will attempt to notify you, by email, to any changes to this privacy policy.</p>
                <h2>Contact Us:</h2>
                <p>If you have any questions regarding our Privacy
                    <span>Policy, or your dealings with our website, please contact us at </span>
                    support@vidown.cc.</p>

            </div>

        </div>
    );
};

export default PrivacyPolicy;