import React from 'react';
// import { useTranslation } from 'react-i18next';
import './TermsOfUse.less';

const TermsOfUse = () => {
    return (
        <div className='terms-use'>
            <div className='terms-use-container'>
                <h2>Welcome to ViDown: Online Video Downloader</h2>
                <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the ViDown.cc website (the "Service") operated by ViDown ("us", "we", or "our").</p>
                <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service</p>
                <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service</p>
                <h2>Intellectual Propert</h2>
                <p>The Service and its original content, features and functionality are and will remain the exclusive property of ViDown and its licensors. Our Service may allow you to download or access content that remains the property of its respective owners and must be used in accordance with these Terms and any other terms specified by those owners</p>
                <h2>Use License</h2>
                <p>Permission is granted to temporarily download one copy of any downloadable materials on the Service's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not</p>
                <p>modify or copy the materials;</p>
                <p>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</p>
                <p>attempt to decompile or reverse engineer any software contained on the Service's website;</p>
                <p>remove any copyright or other proprietary notations from the materials;</p>
                <p>transfer the materials to another person or "mirror" the materials on any other server.</p>
                <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by ViDown at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                <h2>Disclaimer</h2>
                <p>The materials on the Service's website are provided on an 'as is' basis. ViDown makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights</p>
                <h2>Limitation of Liability</h2>
                <p>In no event shall ViDown or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Service's website, even if ViDown or an authorized representative has been notified orally or in writing of the possibility of such damage.</p>
                <h2>Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>
                <p>Contact Us</p>
                <p>If you have any questions about these Terms, please contact us at support@vidown.cc</p>
            </div>
        </div>
    );
};

export default TermsOfUse;