import React, { useState } from 'react';
import { Card, Button, Collapse } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import './ExpandableCard.less'

const { Panel } = Collapse;

const data = [{
    title: 'Q1. Does ViDown work on my mobile device?',
    text: 'Yes, ViDown is a cross-platform video downloader, so you can use it on your any device that has web browser, such as Android Phone, Android Pad, iPhone, iPad, etc.',
    key: 1
}, {
    title: 'Q2. Can ViDown download YouTube to MP3?',
    text: 'Yes, ViDown supports to download YouTube to MP3 320kbps without losing any quality.',
    key: 2
}, {
    title: 'Q3. Is there a download limitation on ViDown Video Downloader?',
    text: 'No, there is no limitation on ViDown. You don’t have to sign in to download YouTube Videos.',
    key: 3
}, {
    title: 'Q4. What quality of video and audio does ViDown support?',
    text: 'For better user experience, ViDown supports 480p, 720p, and 1080p for videos, and 320kbps for audio.',
    key: 4
}]

const ExpandableCard = () => {
    return (

        <div className="expandable-card">
            <div className='expandable-title'>
                <h2>Why Choose ViDown Online Video Downloader</h2>
            </div>
            <Collapse
                defaultActiveKey={['1']}
                ghost
                expandIconPosition='end'
                className="collapse"
                expandIcon={({ isActive }) => { return isActive ? <CloseOutlined /> : <PlusOutlined /> }}
            >
                {data.map(item => {
                    return (
                        <Panel
                            header={<div className='panel-title'><span>{item.title}</span></div>}
                            key={item.key}
                            className='panel'
                        >
                            <span className='panel-text'>{item.text}</span>
                        </Panel>
                    )
                })}
            </Collapse>
        </div>
    );
};

export default ExpandableCard;
