exports.convertBitToUnit = (bit) => {
    const units = ['bit', 'KB', 'MB', 'GB', 'TB']; // 定义可支持的单位

    let unitIndex = 0; // 从 'bit' 开始
    let value = bit;

    // 根据 value 是否大于 1024 来决定是否转换为更大的单位
    while (value >= 1024 && unitIndex < units.length - 1) {
        value /= 1024; // 除以 1024 转换到下一个单位
        unitIndex++;    // 移动到下一个单位
    }
    return `${value.toFixed(2)} ${units[unitIndex]}`; // 保留两位小数并返回结果
}

exports.secondsToHMS = (seconds) => {
    // 计算小时、分钟和秒数
    const hours = Math.floor(seconds / 3600); // 获取小时数
    const minutes = Math.floor((seconds % 3600) / 60); // 获取分钟数
    const secs = seconds % 60; // 获取秒数

    // 格式化为两位数
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = secs < 10 ? `0${secs}` : secs;

    // 返回 HH:mm:ss 格式的时间字符串
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
