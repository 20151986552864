import React, { useState } from 'react';
import { Alert, Spin, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
// import InputForm from '../components/InputForm/InputForm'
import UrlInputForm from './UrlInputForm/UrlInputForm.js';
import DownloadTable from './DownloadTable/DownloadTable';
import VideoInfo from './VideoInfo/VideoInfo';
import DownloadModal from './DownloadModal/DownloadModal';
import DownloadTextbook from './DownloadTextbook/DownloadTextbook';
import DownloaderIntro from './DownloaderIntro/DownloaderIntro';
import ExpandableCard from './ExpandableCard/ExpandableCard';
import Disclaimer from './Disclaimer/Disclaimer';
import DownloadApi from '../../../axios/apis/DownloadApi.js'
import './HomePage.less';

import bilibiliIcon from '../../../assets/platformIcon/bilibili.png'
import facebookIcon from '../../../assets/platformIcon/facebook.png'
import instagramIcon from '../../../assets/platformIcon/Instagram.png'
import tiktokIcon from '../../../assets/platformIcon/tiktok.png'
import xIcon from '../../../assets/platformIcon/X.png'
import youtubeIcon from '../../../assets/platformIcon/youtube.png'

const data = [{
    src: youtubeIcon,
    title: 'youtube',
    path: '/YouTube'
}, {
    src: bilibiliIcon,
    title: 'bilibili',
    path: '/Bilibili'
}, {
    src: facebookIcon,
    title: 'facebook',
    path: '/Facebook'
}, {
    src: instagramIcon,
    title: 'instagram',
    path: '/Instagram'
}, {
    src: tiktokIcon,
    title: 'tiktok',
    path: '/Tiktok'
}, {
    src: xIcon,
    title: 'x',
    path: '/Twitter'
},]

const Home = ({ downloadType }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [videoInfo, setVideoInfo] = useState({});
    const [downloadInfo, setDownloadInfo] = useState([]);
    const [loading, setloading] = useState(false);
    const [showMedaiInfo, setShowMedaiInfo] = useState(false);
    const [medaiUrl, setMedaiUrl] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [downloadParams, setDownloadParams] = useState({});

    const handleDownloadClick = async (url) => {
        const pathName = location.pathname.replace("/", "").toLowerCase();
        const urlRegex = new RegExp(`^https://www\\.${pathName}\\.com/.*\\?v=[a-zA-Z0-9_-]+$`);
        if (!urlRegex.test(url)) {
            message.error(`请输入有效的 ${pathName} 视频链接`);
            setShowMedaiInfo(false)
            return;
        }
        setloading(true);
        const medaiInfo = await DownloadApi.getMediaInfo(url);
        if (medaiInfo && medaiInfo.code === 200) {
            setMedaiUrl(url);
            setShowMedaiInfo(true)
            const data = medaiInfo.data
            setVideoInfo({
                title: data.title,
                img: data.thumbnail,
                duration: data.duration
            });
            setDownloadInfo(data.formats);
            setloading(false);
        } else {
            message.error("服务器异常")
            return;
        }
    };

    const handleDownload = async (formatData) => {
        const params = {
            url: medaiUrl,
            ext: formatData.ext,
            format_id: formatData.format_id,
            title: videoInfo.title
        }
        setDownloadParams(params);
        setIsModalVisible(true);
    }

    const handleClick = (path) => {
        navigate(path); // 跳转到指定路由
    }

    return (
        <div className="home">
            <div className="home-container">
                <div className='home-card-container'>
                    <div className="home-card">
                        <h1 className='card-title'>{`ViDown: Best Online ${downloadType} Converter`}</h1>
                        <div className='card-sub-title'><span>Free online video downloader to download video and audio from YouTube, Facebook, Dailymotion, Twitter, Tiktok, Instagram, and other 10,000+ sites.</span></div>
                        <UrlInputForm onDownload={handleDownloadClick}></UrlInputForm>
                    </div>
                </div>
                <div className='download-platform'>
                    {
                        data.map(item => {
                            return (
                                <div className='platform-item'>
                                    <img src={item.src} alt={item.title} onClick={() => handleClick(item.path)} />
                                </div>
                            )
                        })
                    }
                </div>
                <Spin tip={<div>媒体信息加载中...</div>} spinning={loading} style={{ minHeight: 30 }}>
                    {/* 当 loading 且 showMedaiInfo 为 false 时，渲染占位符 */}
                    {loading && !showMedaiInfo ? (
                        <div style={{ height: 70, width: 200 }}></div>  // 依然保持占位符
                    ) : (
                        showMedaiInfo && (
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <div className='line'></div>
                                <div className='download_table_container'>
                                    <VideoInfo videoInfo={videoInfo} />
                                    <DownloadTable
                                        downloadInfo={downloadInfo || []}
                                        videoInfo={videoInfo || {}}
                                        onDownload={handleDownload}
                                        medaiUrl={medaiUrl}
                                    />
                                </div>
                            </div>

                        )
                    )}
                </Spin>
                <div className='line'></div>
                <DownloadTextbook />
                <div className='line'></div>
                <DownloaderIntro />
                <div className='line'></div>
                <ExpandableCard />
                <div className='line'></div>
                <Disclaimer />
                <DownloadModal
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    downloadParams={downloadParams}
                    videoInfo={videoInfo}
                // onDownloadComplete={handleDownloadComplete}
                />
            </div>
        </div>

    );
};

export default Home;
